<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <table
      :style="style"
    >
      <tbody>
        <tr class="slider-output">
          <td style="text-align: left; width: 33.333%;">
            <template v-if="showMin">
              <output class="slider-minimum">
                {{ min }}
              </output>
            </template>
          </td>
          <td style="text-align: center; width: 33.333%;">
            <template v-if="showValue">
              <output
                class="slider-value"
              >
               {{ value }}
              </output>
            </template>
          </td>
          <td style="text-align: right; width: 33.333%;">
            <template v-if="showMax">
              <output class="slider-maximum">
                {{ max }}
              </output>
            </template>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <input
              :class="`${classes}`"
              :disabled="disabled"
              :min="min"
              :max="max"
              :step="step"
              type="range"
              :style="sliderStyle"
              :value="value"
              @change="onChange"
            >
          </td>
        </tr>
      </tbody>
    </table>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeSlider',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    style() {
      let style = false
      const outlineColor = this.dataViewer.outline_color
      if (outlineColor) {
        style = `outline: 1px solid ${outlineColor};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    min() {
      return this.cell.settings.min || 0
    },
    max() {
      return this.cell.settings.max || 100
    },
    step() {
      return this.cell.settings.step || 1
    },
    showMin() {
      return !this.cell.settings.slider_min_hidden
    },
    showMax() {
      return !this.cell.settings.slider_max_hidden
    },
    showValue() {
      return !this.cell.settings.slider_value_hidden
    },
    gradient() {
      const dataEditor = this.cell
      const settings = dataEditor.settings
      if (!settings.colors) {
        return false
      }
      const gradientColors = []
      for (let i = 0; true; i++) {
        if (settings[`color${i}`]) {
          gradientColors.push(settings[`color${i}`])
        } else {
          break
        }
      }
      if (gradientColors.length) {
        return gradientColors
      }
      return false
    },
    sliderStyle() {
      if (!this.gradient) {
        return false
      }
      const colors = this.gradient.join(', ')
      if (this.gradient.length === 1) {
        return `background: ${colors}; `
      }
      return [
        `background: -webkit-linear-gradient(left, ${colors}); `,
        `background: -o-linear-gradient(right, ${colors}); `,
        `background: -moz-linear-gradient(right, ${colors}); `,
        `background: -ms-linear-gradient(right, ${colors}); `,
        `background: linear-gradient(to right, ${colors}); `
      ].join('')
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const value = builder.getCellValue(index, this.cell, this.clone, 50)
      return value
    }
  },
  mounted() {
    this.applySQLData()
  },
  methods: {
    applySQLData() {
      const builder = this.data.ChecklistBuilder
      if (typeof builder.applySQLData === 'function') {
        builder.applySQLData(this.checklist.index, this.cell, this.dataViewer).then((result) => {
          if (!result || typeof result !== 'object' || !result.value) {
            return
          }
          this.onChange(result.value)
        })
      }
    },
    validate(value) {
      if (value === '') {
        return true
      }
      value = value.replace(',', '.')
      // Überprüfung ob Zahl oder Fließkommazahl.
      if (!/^-?[0-9]+(\.?[0-9]*)?$/.test(value)) {
        return false
      }
      const builder = this.data.ChecklistBuilder
      value = parseFloat(value || 0)
      // Überprüfung ob Intervall eingehalten wird.
      if (builder.moduloFloatSafe(value, parseFloat(this.step || '1')) !== 0) {
        return false
      }
      // Überprüfung ob Minimum unterschritten wurde.
      if (
        value < parseFloat(this.min || 0)
      ) {
        return false
      }
      // Überprüfung ob maximum überschritten wurde.
      if (
        value > parseFloat(this.max || 100)
      ) {
        return false
      }
      return true
    },
    onChange(event) {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const previousValue = builder.getCellValue(index, this.cell, this.clone, false)
      const value = event.target.value
      if (!this.validate(value)) {
        this.$forceUpdate()
        return
      }
      builder.evalValues({
        index: index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: value,
        previousValue: previousValue
      })
    }
  }
}
</script>

<style>

  /* Slider */
  .theme--dark .checklist-viewer .element-content
  input[type="range"] {
    width: 100%;
    margin: 0px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    height: 20px;
    background: #808080 none repeat scroll 0 0;
    padding: 0px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .theme--light .checklist-viewer .element-content
  input[type="range"] {
    width: 100%;
    margin: 0px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    height: 20px;
    background: #f7f9fa none repeat scroll 0 0;
    padding: 0px;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .checklist-viewer .element-content
  input[type=range]::-ms-track {
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  .checklist-viewer .element-content
  input[type=range]::-ms-fill-lower {
    background: transparent;
    border: 1.5px solid #aaa;
    height: 0px;
  }

  .checklist-viewer .element-content
  input[type=range]::-ms-fill-lower {
    background: transparent;
  }

  .checklist-viewer .element-content
  input[type=range]::-ms-fill-upper {
    background: transparent;
    border: 1.5px solid #aaa;
    height: 0px;
  }

  .checklist-viewer .element-content
  input[type=range]::-ms-fill-upper {
    background: transparent;
  }

  .checklist-viewer .element-content
  input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #666;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background: #fff;
    cursor: pointer;
  }

  /* Output */
  .checklist-viewer .element-content
  .slider-output output.slider-value-custom {
    background-color: #ccc;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 0 4px;
    font-weight: bold;
  }
</style>
